
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

$(function () {
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content') }
    });
});


$(document).ready(function(){
	modalCartJQuery = $('#modal-cart');
	modalCartQuotationJQuery = $('#modal-cart-quotation');
    modalComparatorJQuery = $('#modal-comparator');

    popupCart 			= new Foundation.Reveal(modalCartJQuery);
    popupCartQuotation 	= new Foundation.Reveal(modalCartQuotationJQuery);
    popupComparator     = new Foundation.Reveal(modalComparatorJQuery);

    popupRejectQuotatio = new Foundation.Reveal($('#reject-quotation-reveal'));

    popupAddStarProductToUserCart = new Foundation.Reveal($('#add-star-product'));


    $('body').on('click', '[data-action="open-modal-cart-quotation"]', function(){
    	popupCartQuotation.open();
    })
    $('body').on('click', '[data-action="open-modal-cart"]', function(){
    	popupCart.open();
    })

    $('body').on('click', '[data-action="open-add-star-product"]', function(){
    	$sourceButton = $(this);
    	if($sourceButton.data('source') == "banniere_is_reconditionned"){
			$('#add-star-product').data('source', 'reconditionned');
    	}else{
			$('#add-star-product').data('source', 'star');
    	}
    	popupAddStarProductToUserCart.open();
    })

    $('body').on('open.zf.reveal', '#add-star-product', function() {
  console.log('This works');
  console.log($(this).data('source'));
  console.log($('#add-star-product').data('source'));

  	if($('#add-star-product').data('source') == "reconditionned"){
  		console.log('test');
  		$('#source').val("Produit reconditionné");
  	}
  	else{
  		console.log('test2');
  		$('#source').val("");
  	}


});


    $('body').on('open.zf.reveal', function(){


        $('.sliderelated-modal:not(.slick-initialized)').slick({
            dots: false,
            infinite: false,
            arrows: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 2,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
            ]
        });
    });

    var popupNewsletter = new Foundation.Reveal($('#newsletterSignInWidget'));

    var popupAddAddress = new Foundation.Reveal($('#add-delivery-address'));
    var popupAddAddressPick = new Foundation.Reveal($('#add-delivery-address-pick'));

    var popupAddVoucher = new Foundation.Reveal($('#add-cart-voucher'));

    var popupAddCommentProduct = new Foundation.Reveal($('#add-product-comment'));

    var popupSendNegociationRequest = new Foundation.Reveal($('#send-negociation-request'));

    $('[data-confirm]').click(function(e){
    	if($(this).is('[data-authorize]')){
    		window.location.href = $(this).attr('href');
    	}else{
	    	e.preventDefault();

	    	$text = $(this).attr('data-confirm');
	    	$link = $(this);

			jconfirm({
				useBootstrap: false,
				boxWidth: '40%',
			    title: '<i class="fi-alert"></i> Cette action néccessite une confirmation de votre part !',
			    content: $text,
			    buttons: {
			        cancel: {
			        	btnClass: 'button pink',
			        	text : 'Annuler',
			        },
			        confirm: {
			        	btnClass: 'button red',
			        	text : "Valider",
			        	action: function () {

					    	$link.attr('data-authorize', '')
					    	$link.click();
					    	return true;
				        }
			        },

			    }
			});
		}

    });

/*    $('body').on('click', '[data-confirm]', function(e){
    	$confirmText = $(this).data('confirm-text');

		jconfirm({
			useBootstrap: false,
			boxWidth: '30%',
		    title: 'Demande de confirmation',
		    draggable: false,
		    content: $confirmText,
		    buttons: {
		        cancel:	{
		        	btnClass: 'button pink',
		        	text : "Annuler",
		        	action: function () {

			        }
		        },
		        confirm: {
		        	btnClass: 'button red',
		        	text : "Confirmer",
		        	action: function () {
						$.ajax({
							url : routes['delete-my-address'],
							type : 'GET',
							data : {"id" : $id},
							contentType : 'json'
						})
						  .done(function(resp){
						  	if(resp.state)
						  		$type = "green";
						  	else
						  		$type = "red";

				            jconfirm({
				            	content:resp['feedback'],
								type: $type,
								typeAnimated: true,
				            });
						});

			        }
		        },
		    }
		});

    });
*/
    $('body').on('click', '[data-pop-in="edit-address"]', function(e){
    	$id = $(this).data('address-id');

		jconfirm({
			useBootstrap: false,
			boxWidth: '30%',
		    title: 'Gestion des adresses',
		    draggable: true,
		    content: 'Voulez vous supprimer l\'adresse ?',
		    buttons: {
		        cancel:	{
		        	btnClass: 'button pink',
		        	text : "Annuler",
		        	action: function () {
//			            jconfirm({content:'Canceled !'});
			        }
		        },
		        confirm: {
		        	btnClass: 'button red',
		        	text : "Valider",
		        	action: function () {
						$.ajax({
							url : routes['delete-my-address'],
							type : 'GET',
							data : {"id" : $id},
							contentType : 'json'
						})
						  .done(function(resp){
						  	if(resp.state)
						  		$type = "green";
						  	else
						  		$type = "red";

				            jconfirm({
				            	content:resp['feedback'],
								type: $type,
								typeAnimated: true,
				            });
						});

			        }
		        },
/*		        somethingElse: {
		            text: 'Something else',
		            btnClass: 'btn-blue',
		            keys: ['enter', 'shift'],
		            action: function(){
		                jconfirm({content:'Something else?'});
		            }
		        }*/
		    }
		});

/*	    $.ajax({
	    	url : routes['get-address'],
	    	type : 'GET',
	    	data : {"id" : $id},
	    	contentType : 'json'
	    })
		  .done(function(resp){
		  	console.log(resp);
		});
*/

    });


	$('body').on('submit', '[form-ajax="true"]', function(e){
		e.preventDefault();
		e.stopPropagation();

		$inboxModal = $(this).closest('.inner');

		$datas = new FormData($(this)[0]);

		$.ajax({
			type: "POST",
			url: $(this).attr('action'),
			data: $datas,
			processData: false,
			contentType: false,
			dataType: "html",
			success: function(data, textStatus, jqXHR) {
				console.log(data);
				$inboxModal.html(data);
				setTimeout(function(){
					$inboxModal.find('.ico').addClass('drawn');
				}, 300);

				//process data
			},
			error: function(data, textStatus, jqXHR) {
				//process error msg
			},
		})


	})

    $('body').on('click', '.add-to-comparator', function(){

        $productId = $(this).data('id');


        $.ajax({
            type: "POST",
            url: $(this).data('url'),
            data: 'id='+$productId,
            dataType: "json",
            success: function(data, textStatus, jqXHR) {
                console.log(data);
                if(data.resultCode == "Success"){

                    if(data.hasOwnProperty("comparator")){
                        $('#modal-comparator').html(data['comparator']);
                        $('[data-open="comparator"]').html('<i class="fa fa-list-chart"></i> Comparateur ( <span>'+data['nbProduct']+'</span> )');
                        popupComparator.open();
                    }

                }else{
                    alert('TODO : Une erreur est survenue :\n\r'+data.resultMsg)
                }
            },
            error: function(data, textStatus, jqXHR) {
                //process error msg
            },
        })
    });

	$('body').on('submit', '.add-cart-form', function(e){
		e.preventDefault();
		e.stopPropagation();

		$datas = new FormData($(this)[0]);

		$.ajax({
			type: "POST",
			url: $(this).attr('action'),
			data: $datas,
			processData: false,
			contentType: false,
			dataType: "json",
			success: function(data, textStatus, jqXHR) {
				console.log(data);
				if(data.resultCode == "Success"){
					if(data.hasOwnProperty("user-cart")){
						$('#modal-cart').html(data['user-cart']);
						$('a[data-action="open-modal-cart"]').html('<i class="fi-shopping-cart"></i> Panier ( <span>'+data['nbProduct']+'</span> )');
						popupCart.open();
					}else{
						$('#modal-cart-quotation').html(data['user-cart-quotation']);
						$('a[data-action="open-modal-cart-quotation"]').html('<i class="fa fa-gavel"></i> Devis ( <span>'+data['nbProduct']+'</span> )');
						popupCartQuotation.open();
					}

				}else{
					alert(data.resultMsg)
				}
			},
			error: function(data, textStatus, jqXHR) {
				//process error msg
			},
		})


	})


	$(document).on('closed.zf.reveal', '[data-reveal][data-refresh-on-close]', function () {
		console.log('kkkkk');
		location.reload();
	});


	$(window).scroll(function() {

		var height = $('#menu-main-nav-horizontal').outerHeight();


/*		console.log($(document).height(), $(window).height() + (216-52));
		console.log($(document).height(), $(window).height() + (350-height));
*/
/*
		if($(document).height() > $(window).height() + (216-52)){
			if ($(this).scrollTop() > (197-52)){
				$('#page-header').addClass("sticky");
			}
			else if($(this).scrollTop() <= 1){
				$('#page-header').removeClass("sticky");
			}
		}else{
			if ($(this).scrollTop() <= 1){
				$('#page-header').removeClass("sticky");
			}
		}
*/
		if($(document).height() > $(window).height() + (350-height)){
			if ($(this).scrollTop() > (350-height)){
				$('#page-header').addClass("sticky");
				$('header#page-header.sticky + .main-content').css('padding-top', height).css('min-height', 'calc(100vh - '+height+'px - 85px)');

			}
			else if($(this).scrollTop() <= 1){
				$('header#page-header.sticky + .main-content').css('padding-top', '').css('min-height', '');
				$('#page-header').removeClass("sticky");
			}
		}else{
			if ($(this).scrollTop() <= 1){
				$('header#page-header.sticky + .main-content').css('padding-top', '').css('min-height', '');
				$('#page-header').removeClass("sticky");
			}
		}

		$('.dropdown.menu > li.opens-right > .is-dropdown-submenu').css('max-height', 'calc(95vh - '+$('#page-header').outerHeight()+'px)');


	});


	$('.slider-for').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.slider-nav',
		lazyLoad: 'ondemand',
		autoplay: true,
		autoplaySpeed: 4000,
	});

	$('.slider-nav').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.slider-for',
		dots: true,
		centerMode: true,
		focusOnSelect: true,
		lazyLoad: 'ondemand',
	});


	$('span.raty.enable').raty({
		'scoreName' : 'note',
		'score' : 5,
		'click' : function(score, evt){

		}
	});

	$(window).resize();

})


    $(document).ready(function(){
        $('.sliderelated').slick({
            dots: false,
            infinite: false,
            arrows: true,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 2,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
            ]
        });
    })
